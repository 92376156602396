.circle-icon {
  background-color: #ffff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

.clickable {
  cursor: pointer;
}
